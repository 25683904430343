<template>
  <el-row :gutter="10">
    <el-col :lg="16" :md="12" :sm="24" :xs="24">
      <div class="table-header">
        <div class="theader-left">
          <span>地块编号管理</span>
        </div>

        <div class="theader-right">
          <div style="display: inline-block">
            <el-input v-model.trim="inputValue" placeholder="请输入项目名称" />
          </div>
          <el-select v-model="value" placeholder="Please input">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button @click="handleQuery" size="medium">查询</el-button>
          <el-button @click="handleReset" style="margin-left: 0px" size="medium"
            >重置</el-button
          >
          <el-button @click="exportExcel()" type="primary" size="medium"
            >导出</el-button
          >
        </div>
      </div>
      <PublicTable ref="publicTable" @skips="getSkipFile"></PublicTable>
    </el-col>
    <el-col :lg="8" :md="12" :sm="24" :xs="24">
      <ShowFile ref="showFile"></ShowFile>
    </el-col>
  </el-row>
</template>

<script>
import ShowFile from "../file/ShowFile";

import table from "@/static/table.json";
// import { ElMessageBox, ElMessage } from "element-plus";
import PublicTable from "@/components/table/PublicTable.vue";
import LoadData from "@/static/GetFileds/ParcelList.json";
// 表格相关
// import Blob from "@/excel/Blob.js";
// import Export2Excel from "./excel/Export2Excel.js";
// 引入导出Excel表格依赖
// import FileSaver from "file-saver";
// import XLSX from "xlsx";
export default {
  components: {
    ShowFile,
    PublicTable,
  },
  data() {
    return {
      // LoadData:[],
      inputValue: "", //搜索的内容
      tableDataAll: [], //表格全部数据
      options: [], //选项
      value: "", //当前选项
      url: "", //传给子组件的url
    };
  },
  methods: {
    // 跳转file，之前是路由跳转，现在改为父传子  优化部分：点击补充资料时右边再出现。
    getSkipFile(item) {
      // this.$router.push("/File/ShowFile");
      console.log('获取子的：',item);
      this.$refs.showFile.getLandFile(item);
    },

    //得到全部数据
    async getDataListall() {
      let res = await this.$ajax.get("/api/user/listall", {});
      console.log("响应全部数据：", res);
      this.tableDataAll = res.data.list;
    },

    //搜索
    handleQuery() {
      // this.getDataList();
      this.$refs.publicTable.getDataList(this.url, this.inputValue);
    },
    //重置
    handleReset() {
      this.inputValue = "";
      this.$refs.publicTable.getDataList(this.url, this.inputValue);
    },

    //定义导出Excel表格事件
    exportExcel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/Export2Excel");
        const tHeader = [
          "土地码2",
          "项目名称2",
          "坐落",
          "用地单位",
          "当前备案类型",
        ]; // 这是excel文档第一行显示的标题
        const filterVal = [
          "Id",
          "ProjectName",
          "LandUser",
          "LandLocation",
          "Type",
        ]; // id，version等都是上面标题所对应的数据
        const list = this.tableDataAll; //把data里的数组tableData存到list中
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "地块表格"); //标题，数据，文件名
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
  mounted() {
    this.url = "/api/user/list";
    this.$refs.publicTable.getLoadData(LoadData, this.url);
    this.getDataListall();//这是给导出表格准备的
    this.options = table.options;
    this.value = this.options[0].value;
  },
};
</script>

<style scoped>
.table-header {
  padding: 10px;
  overflow: hidden;
  /* border: 1px solid red; */
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-header .el-select {
  width: 100px;
  margin: 0 10px 0 10px;
}

.theader-left {
  min-width: 100px;
}
</style>